
import { defineComponent } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TmButton from '@/components/shared/TmButton.vue'
import useCountriesOpenModal from '@/compositions/services/useCountriesOpenModal'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  name: 'CountriesFilter',
  components: {
    TmButton,
    TopFilter,
  },
  setup() {
    const { isSmMax } = useBreakpoints()
    const { openModalRestoreSenderSettings } = useCountriesOpenModal()

    return {
      openModalRestoreSenderSettings,
      isSmMax,
    }
  },
})
