
import { defineComponent, ref, watch, computed } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import { senderSettingsOptions } from '@/definitions/_general/_data/optionsList'
import { useModals } from '@/compositions/modals'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  name: 'CountriesTableEdit',
  components: { TmButton },
  props: {
    fixedWidth: {
      type: Boolean,
    },
  },
  setup() {
    const { isSmMax } = useBreakpoints()
    const { openModal } = useModals()

    const isEdit = ref(false)
    const select = ref(senderSettingsOptions[0])
    const onBlur = () => {
      isEdit.value = false
    }
    const onSelect = onBlur

    const showField = computed(() => isEdit.value && !isSmMax.value)
    const showModal = computed(() => isEdit.value && isSmMax.value)

    watch(showModal, (newValue) => {
      if (newValue) {
        openModal('editSenderModal')
        isEdit.value = false
      }
    })

    return {
      senderSettingsOptions,
      isEdit,
      select,
      onBlur,
      onSelect,
      isSmMax,
      showField,
    }
  },
})
