
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmButton from '@/components/shared/TmButton.vue'
import CountriesTableEdit from '@/components/pages/services/countries/countries/CountriesTableEdit.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { SenderCountries } from '@/definitions/services/countries/types'
import { useBreakpoints } from '@/compositions/breakpoints'

type TitleSize = 'small' | ''

export default defineComponent({
  name: 'CountriesTable',
  components: { CountriesTableEdit, TmButton, TmStatus, TmCountry, TmTable },
  props: {
    title: {
      type: String,
    },
    titleSize: {
      type: String as PropType<TitleSize>,
    },
    items: {
      type: Array as PropType<SenderCountries[]>,
      required: true,
    },
    mobileHideBadges: {
      type: Boolean,
    },
    headers: {
      type: Array as PropType<TableHeaders[]>,
    },
  },
  setup() {
    const { isSmMax } = useBreakpoints()

    return {
      isSmMax,
    }
  },
})
